import React, { useState , useEffect } from 'react';

import ContentLangText from '../ProductList/ContentLangText';
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';


export default function ProductContent({product, classes}) {
 
const linkUrl = TOGO.Util.getBasePath() + product.url;

const toUpperCase = (val)=>{
    if (val!=undefined && val !=""){
        return val.toUpperCase();
    }else{
        return ""
    }
} 

const  renderSwitch = (param) =>{
    switch(param) {
      case 'doc':
        return '<img src="assets/icon-png/DOC.png" />';
    case 'docx':
        return ' <img src="assets/icon-png/DOCX.png" />';
    case 'xls':
        return '<img src="assets/icon-png/xls.png" />';
    case 'xlsx':
        return '<img src="assets/icon-png/xlsx.png" />';    
      default:
        return ' <span class="symbolwrap ' +param+'">'
        +' <i class="fa fa-file-image-o" aria-hidden="true"></i><span> '
        + toUpperCase(param.itemPicture.contentTypeSymbol)+' </span></span>';
    }
  }

  return (
    <Link href={linkUrl}>
      <div className=" col contentlangRelevantItem pictureWrapper_ responsiveImage_">
        {product.itemPicture && (
          <>
            {product.itemPicture.noThumbnail ? (
              <>
                <div className="noPreviewSymbol">
                  {this.renderSwitch(product.itemPicture.contentTypeSymbol)}
                </div>
              </>
            ) : (
              <CardMedia
                src={product.itemPicture?.thumbnailUri}
                sx={{
                  bgcolor: '#00000008',
                  width: '11rem',
                  borderRadius: '9px ',
                  border: '0.5px solid #0000003b',
                  height: '9rem',
                  fontSize: 15,
                  objectFit: 'scale-down !important'
                }}
                variant="square"
                component="img"             
                title={product.itemPicture?.altText ? product.itemPicture?.altText : null}
              ></CardMedia>
            )}
          </>
        )}
      </div>
      <div
        className={
          ' col action editInstanceAction_ ' +
          (product.actions.length > 2 ? ' smallerTitle_' : '')
        }
      >
        <CardContent className={classes.cardContentStyle}>

        <ContentLangText
                  contentText={product.contentLangTitle}
                  product={product}
                  classes={classes}
                />
          <Typography
            className={classes.subTitle}
            noWrap={true}
            component="div"
          >{product.description1}</Typography>
          <Typography
            className={classes.subTitle}
            noWrap={true}
            component="div"
          >{product.description2}</Typography>
        </CardContent>
      </div>
    </Link>
  );
}
