import React, { useState, useEffect } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { useDataManagement } from '../../datamanagement/DataManagementContext';
import TabEditorDisplayNode from './TabEditorDisplayNode';
import { standardEditor } from '../standardEditor';
import { CLASSIFICATION_GROUP_ALL } from '../../datamanagement/InstanceEditor';
import { removeMandatorySetup } from 'src/jsx/producttype/PtdDefaultDataOverview';

import ajaxGet from '../../../../services/ajaxGet';

const PTD = 'ProductTypeDefinition';

export const InstanceTabEditorReader = ({
  readOnly,
  selectedNodesContent,
  instanceType,
  instanceId,
  loadedInstanceData,
  startingRootNodeId,
  loadedNodesContent,
  loadedContentLang,
  flatNodesListToWrap,
  Wrapper,
  firstComponent
}) => {
  const {
    setContentLang,
    setLoadedInstance,
    setLoadedTextState,
    setCustomFieldCommonSelectionOptions,
    setVariableContentDefs,
    setFeatureDefs,
    getClassificationGroup,
    isDefaultData,
    setDefaultData,
    isBlockDefaultData,
    setBlockDefaultData,
    subscriptionInfo,
    setSubscriptionInfo,
    userPermissions,
    setUserPermissions,
    articleDataZones,
    setArticleDataZones
  } = useDataManagement();

  const hasNestedNodes = flatNodesListToWrap === undefined;
  let rootNodeId = startingRootNodeId ? startingRootNodeId : 'ROOT';
  rootNodeId = hasNestedNodes ? rootNodeId : null;
  const [nodesContent, setNodesContent] = useState(loadedNodesContent);
  const [loadingNodesContent, setLoadingNodesContent] = useState(true);
  const [loadingTogoData, setLoadingTogoData] = useState(true);
  const [loadingDataZones, setLoadingDataZones] = useState(true);

  const [instanceEditor, setInstanceEditor] = useState(null);
  const [loadingInstanceData, setLoadingInstanceData] = useState(true);
  const [error, setError] = useState(null);
  const [nodesContentError, setNodesContentError] = useState(null);

  let classificationGroup = null;
  if (!firstComponent) {
    classificationGroup = getClassificationGroup();
    if (classificationGroup === CLASSIFICATION_GROUP_ALL) {
      classificationGroup = null;
    }
  }

  useEffect(() => {
    if (!instanceType || !instanceId) {
      let $reactInstanceEditor = $('#react-InstanceEditor');
      let $instanceWrap = $reactInstanceEditor.closest('.instanceEditor');
      let objectClassName = $instanceWrap.data('classname');

      if (objectClassName === 'CustomInstance') {
        instanceType = $instanceWrap.data('subtype');
      } else {
        instanceType = objectClassName;
      }
      instanceId = $instanceWrap.data('id');
    }

    let contentLang;
    if (loadedContentLang) {
      contentLang = loadedContentLang;
    } else {
      contentLang = $('#contentLangSelectElement').val() || null;
      setContentLang(contentLang);
    }

    if (!loadedNodesContent) {
      if (selectedNodesContent) {
        setNodesContent(selectedNodesContent);
        setLoadingNodesContent(false);
      } else {
        setLoadingNodesContent(true);
        ajaxGet('tabEditor/getAppliedEditorConfig', {
          instanceTypeTarget: instanceType
        }).then((ajaxData) => {
          let isLoadedNodesEmptyError = false;
          if (!ajaxData.error && ajaxData.response) {
            let standard = false;
            if (ajaxData.response.editorConfigPojo === null) {
              loadedNodesContent = standardEditor;
              standard = true;
            } else {
              loadedNodesContent =
                ajaxData.response.editorConfigPojo?.configurationJson;
            }
            if ('roleTabEditor' === ajaxData.response.resultInfo) {
              loadedNodesContent = null;
            }

            if (loadedNodesContent) {
              if (!standard) {
                loadedNodesContent = JSON.parse(loadedNodesContent);
              }
              setNodesContent(loadedNodesContent);
            } else {
              setNodesContentError(''); //TODO: error text  Keine Editor-Ansicht gefunden.
              isLoadedNodesEmptyError = true;
            }
          }
          if (!isLoadedNodesEmptyError) {
            setNodesContentError(ajaxData.error);
          }

          setLoadingNodesContent(false);
        });
      }
    } else {
      setLoadingNodesContent(false);
    }

    if (loadedInstanceData) {
      let loadedInstanceDataCopy = JSON.parse(
        JSON.stringify(loadedInstanceData)
      );
      readInstanceData(loadedInstanceDataCopy, contentLang, readOnly);
      setError(null);
      setLoadingInstanceData(false);
    } else {
      loadInstanceData(
        contentLang,
        setVariableContentDefs,
        classificationGroup
      );
    }

    if (subscriptionInfo == null || userPermissions == null) {
      ajaxGet('dataManagement/subscriptionAndPermissionsInfo', {}).then(
        (ajaxData) => {
          if (!ajaxData.error && ajaxData.response) {
            setSubscriptionInfo(ajaxData.response.subscriptionInfo);
            setUserPermissions(ajaxData.response.permissions);
          }

          setLoadingTogoData(false);
        }
      );
    } else {
      setLoadingTogoData(false);
    }

    if (articleDataZones == null) {
      ajaxGet('articleDataZones', {filterUserDataZones: true}).then((ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          let responseDataZones = ajaxData.response.dataZones;
          if (responseDataZones && responseDataZones.length) {
            responseDataZones.sort((a, b) => {
              if (!a.label) return a;
              if (!b.label) return b;
              a.label.localeCompare(b.label, undefined, {
                sensitivity: 'base'
              });
            });
            setArticleDataZones(responseDataZones);
          }
        }

        setLoadingDataZones(false);
      });
    } else {
      setLoadingDataZones(false);
    }
  }, [selectedNodesContent, classificationGroup]);

  function readInstanceData(instanceData, contentLang, readOnly) {
    let newInstanceState = {
      resolvedVariableTexts: {},
      media: {},
      customFields: {},
      features: {},
      generalFields: {},
      selectedKeywords: [],
      keywordOptions: []
    };
    let newInstanceEditor = {
      textEditor: {},
      mediaEditor: {},
      customFieldEditor: {},
      featureEditor: {}
    };
    newInstanceEditor.instanceId = instanceId;
    newInstanceEditor.instanceType = instanceType;
    newInstanceEditor.containerId = instanceData.containerId;
    newInstanceEditor.contentLang = contentLang;

    for (let textType in instanceData.textEditorsMap) {
      if (!textType) continue;
      const textEditor = instanceData.textEditorsMap[textType];
      let isDefaultDataServer = Boolean(
        textEditor?.defaultDataAwareInfo?.defaultData
      );
      let isBlockDefaultDataServer =
        textEditor?.defaultDataAwareInfo?.blockDefault;

      let dataObject = {};
      dataObject.id = textType;
      dataObject.type = 'textEditor';
      dataObject.instanceId = instanceId;

      if (isDefaultDataServer) {
        if (!isDefaultData(dataObject)) {
          setDefaultData(dataObject);
        }
      }
      if (isBlockDefaultDataServer) {
        if (!isBlockDefaultData(dataObject)) {
          setBlockDefaultData(dataObject);
        }
      }
      let text = textEditor.text;
      if (textType in instanceData.textWithValuesEditorsMap) {
        let textFromServer = instanceData.textWithValuesEditorsMap[textType];
        newInstanceState.resolvedVariableTexts[textType] = textFromServer;
      }
      setLoadedTextState(textEditor.id, text);
      delete textEditor.text; //cleanup instance editor
      newInstanceEditor.textEditor[textType] = textEditor;
    }

    for (let mediaType in instanceData.mediaEditors) {
      const mediaEditor = instanceData.mediaEditors[mediaType];
      let isDefaultDataServer = Boolean(
        mediaEditor?.defaultDataAwareInfo?.defaultData
      );
      let isBlockDefaultDataServer =
        mediaEditor?.defaultDataAwareInfo?.blockDefault;

      let dataObject = {};
      dataObject.id = mediaType;
      dataObject.type = 'mediaEditor';
      dataObject.instanceId = instanceId;

      if (isDefaultDataServer) {
        setDefaultData(dataObject);
      }

      if (isBlockDefaultDataServer) {
        setBlockDefaultData(dataObject);
      }

      newInstanceState.media[mediaType] = mediaEditor.mediumModels;
      delete mediaEditor.mediumModels; //cleanup instance editor
      newInstanceEditor.mediaEditor[mediaType] = mediaEditor;
    }

    for (let customFieldType in instanceData.customFields) {
      const customFieldEditor = instanceData.customFields[customFieldType];
      let isDefaultDataServer = Boolean(
        customFieldEditor?.defaultDataAwareInfo?.defaultData
      );
      let isBlockDefaultDataServer =
        customFieldEditor?.defaultDataAwareInfo?.blockDefault;

      let dataObject = {};
      dataObject.id = customFieldType;
      dataObject.type = 'customField';
      dataObject.instanceId = instanceId;

      if (isDefaultDataServer) {
        setDefaultData(dataObject);
      }
      if (isBlockDefaultDataServer) {
        setBlockDefaultData(dataObject);
      }

      const component = customFieldEditor.featureComponent;
      const value = readFeatureValueForInstance(
        component,
        customFieldEditor,
        setCustomFieldCommonSelectionOptions
      );

      newInstanceState.customFields[customFieldType] = value;
      newInstanceEditor.customFieldEditor[customFieldType] = customFieldEditor;
    }

    for (let featureKey in instanceData.features) {
      const featureEditor = instanceData.features[featureKey];
      const componentFeature = featureEditor.featureComponent;
      let isDefaultDataServerFeature = Boolean(
        featureEditor?.defaultDataAwareInfo?.defaultData
      );

      let isBlockDefaultDataServerFeature =
        featureEditor?.defaultDataAwareInfo?.blockDefault;

      let dataObjectFeature = {};
      dataObjectFeature.id = featureKey;
      dataObjectFeature.type = 'feature';
      dataObjectFeature.instanceId = instanceId;

      if (isDefaultDataServerFeature) {
        setDefaultData(dataObjectFeature);
      }

      if (isBlockDefaultDataServerFeature) {
        setBlockDefaultData(dataObjectFeature);
      }

      const valueFeature = readFeatureValueForInstance(
        componentFeature,
        featureEditor,
        setCustomFieldCommonSelectionOptions
      );

      newInstanceState.features[featureKey] = valueFeature;
      newInstanceEditor.featureEditor[featureKey] = featureEditor;
    }

    for (let generalFieldType in instanceData.generalFields) {
      const generalField = instanceData.generalFields[generalFieldType];
      newInstanceState.generalFields[generalFieldType] = generalField;
    }

    newInstanceState.selectedKeywords = instanceData.keywords.map(
      (selectedKeywordValue) => ({
        selectionValue: selectedKeywordValue,
        addToValueList: false,
        addedSelectionOption: false
      })
    );
    newInstanceState.selectedKeywords = newInstanceState.selectedKeywords.concat(
      instanceData.customLanguageKeywordValues.map((customKeyword) => ({
        selectionValue: customKeyword,
        addToValueList: false,
        addedSelectionOption: true
      }))
    );
    newInstanceState.keywordOptions = instanceData.customLanguageKeywordValues.map(
      (customKeyword) => ({
        selectionValue: customKeyword,
        addToValueList: false,
        addedSelectionOption: true,
        label: customKeyword
      })
    );

    newInstanceEditor.readOnly = Boolean(readOnly);

    setLoadedInstance(instanceId, newInstanceState);
    setInstanceEditor(newInstanceEditor);
  }

  function loadInstanceData(
    contentLang,
    setVariableContentDefs,
    classificationGroup
  ) {
    let url;
    let targetInstanceId = instanceId;
    let payloadLoadInstance = {
      contentLanguage: contentLang
    };

    switch (instanceType) {
      case 'Product':
        url = 'dataManagement/product';
        break;
      case 'Article':
        url = 'dataManagement/article';
        payloadLoadInstance.classificationGroup = classificationGroup;
        break;
      case 'PtdDefaultDataForProduct':
        url = 'dataManagement/productTypeDefinition';
        targetInstanceId = instanceId.replace('PtdDefaultDataForProduct', '');
        payloadLoadInstance.classificationGroup = classificationGroup;
        break;
      case 'PtdDefaultDataForArticle':
        url = 'dataManagement/productTypeDefinition';
        targetInstanceId = instanceId.replace('PtdDefaultDataForArticle', '');
        payloadLoadInstance.classificationGroup = classificationGroup;
        break;
      default:
        url = 'dataManagement/customInstance';
        payloadLoadInstance.customInstance = instanceType;
        break;
    }

    payloadLoadInstance.id = targetInstanceId;

    ajaxGet(url, payloadLoadInstance).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        readLoadedInstanceData(
          ajaxData.response,
          contentLang,
          setVariableContentDefs
        );
      }

      setError(ajaxData.error);
      setLoadingInstanceData(false);
    });
  }

  function readLoadedInstanceData(
    response,
    contentLang,
    setVariableContentDefs
  ) {
    let instanceEditorToRead;

    switch (instanceType) {
      case 'Product': {
        instanceEditorToRead = response.productEditModel.instanceEditor;
        let variableContentDefs = response.variableContentDefs;
        setVariableContentDefs(instanceType, variableContentDefs);
        break;
      }
      case 'Article': {
        instanceEditorToRead = response.articlesModel.articleModels[0];

        let variableContentDefs = response.variableContentDefs;
        setVariableContentDefs(instanceType, variableContentDefs);

        setFeatureDefs(response.articlesModel.features);

        break;
      }
      case 'PtdDefaultDataForProduct': {
        instanceEditorToRead = response.ptdModelForProduct;
        removeMandatorySetup(instanceEditorToRead);

        let variableContentDefsArticle = response.variableContentDefsProduct;
        setVariableContentDefs(PTD, variableContentDefsArticle);

        instanceType = PTD;
        break;
      }
      case 'PtdDefaultDataForArticle': {
        instanceEditorToRead = response.ptdModelForArticle;
        removeMandatorySetup(instanceEditorToRead);

        let variableContentDefsArticle = response.variableContentDefsArticle;
        setVariableContentDefs(PTD, variableContentDefsArticle);

        let featureDefs = response.featureDefs;
        setFeatureDefs(featureDefs);

        instanceType = PTD;
        break;
      }
      default: {
        let resultList = response.childrenInstanceModels;
        instanceEditorToRead = resultList ? resultList[0] : null;
        removeMandatorySetup(instanceEditorToRead);

        let variableContentDefsArticle = response.variableContentDefs;
        setVariableContentDefs(instanceType, variableContentDefsArticle);

        break;
      }
    }

    readInstanceData(instanceEditorToRead, contentLang, readOnly);
  }

  if (error || nodesContentError) {
    return <div>{(error && 'Error: ' + error) || nodesContentError}</div>;
  } else if (
    loadingInstanceData ||
    loadingNodesContent ||
    loadingTogoData ||
    loadingDataZones
  ) {
    if (hasNestedNodes) {
      return <LinearProgress color="secondary" />;
    } else {
      return null;
    }
  } else {
    if (!nodesContent) return null;

    if (hasNestedNodes) {
      let $reactInstanceEditor = $('#react-InstanceEditor');
      if (rootNodeId === 'ROOT') {
        $reactInstanceEditor.addClass('loadedReactInstanceEditor');
        $reactInstanceEditor.parent().addClass('loadedReactInstanceEditor');
      }

      return (
        <div>
          <TabEditorDisplayNode
            node={nodesContent[rootNodeId]}
            nodesContent={nodesContent}
            instanceEditor={instanceEditor}
          />
        </div>
      );
    }

    //flat nodes will be wrapped in provided wrapper:
    else {
      return (
        <>
          {flatNodesListToWrap.map((nodeToWrap) => {
            return (
              <Wrapper key={nodeToWrap.wrapperId}>
                <TabEditorDisplayNode
                  node={nodesContent[nodeToWrap.nodeId]}
                  nodesContent={nodesContent}
                  instanceEditor={instanceEditor}
                />
              </Wrapper>
            );
          })}
        </>
      );
    }
  }
};

function readFeatureValueForInstance(
  component,
  featureValueEditor,
  setCustomFieldCommonSelectionOptions
) {
  let value = null;
  if (component.rangeType) {
    value = {
      lowerValue: readFeatureValue(
        component.lowerEndpointFeatureComponent,
        setCustomFieldCommonSelectionOptions
      ),
      upperValue: readFeatureValue(
        component.upperEndpointFeatureComponent,
        setCustomFieldCommonSelectionOptions
      )
    };
  } else {
    value = readFeatureValue(
      component,
      featureValueEditor,
      setCustomFieldCommonSelectionOptions
    );
  }
  return value;
}

function readFeatureValue(
  component,
  featureValueEditor,
  setCustomFieldCommonSelectionOptions
) {
  let value = null;

  if (
    featureValueEditor &&
    (featureValueEditor.readOnly || featureValueEditor.isDerivedFeature)
  ) {
    value = component.stringValue;
    delete component.stringValue; //cleanup instance editor
    return value;
  }

  switch (component.featureType) {
    case 'FEATURE_TYPE_BOOLEAN':
      if (component.stringValue == 'featureValueBooleanTrue') {
        value = true;
      } else if (component.stringValue == 'featureValueBooleanFalse') {
        value = false;
      } else {
        value = null;
      }

      delete component.stringValue; //cleanup instance editor
      break;
    case 'FEATURE_TYPE_NUMERIC':
      value = component.componentValue;
      delete component.componentValue; //cleanup instance editor
      break;
    case 'FEATURE_TYPE_LOCAL_DATE':
      value = null;
      if (component.date) {
        value = {
          day: component.date.dayOfMonth,
          month: component.date.month,
          year: component.date.year
        };
      }

      delete component.date; //cleanup instance editor
      break;
    case 'FEATURE_TYPE_LOCAL_TIME':
      value = null;
      if (component.time) {
        value = {
          hour: component.time.hour,
          minute: component.time.minute
        };
      }

      delete component.time; //cleanup instance editor
      break;
    case 'FEATURE_TYPE_LOCAL_DATE_TIME':
      value = null;
      if (component.date && component.time) {
        value = {
          day: component.date.dayOfMonth,
          month: component.date.month,
          year: component.date.year,
          hour: component.time.hour,
          minute: component.time.minute
        };
      }

      delete component.date; //cleanup instance editor
      delete component.time; //cleanup instance editor
      break;
    case 'FEATURE_TYPE_ZONED_DATE_TIME':
      value = null;
      if (component.date && component.time) {
        value = {
          day: component.date.dayOfMonth,
          month: component.date.month,
          year: component.date.year,
          hour: component.time.hour,
          minute: component.time.minute,
          timeZone: component.zoneId
        };
      }

      delete component.date; //cleanup instance editor
      delete component.time; //cleanup instance editor
      delete component.zoneId; //cleanup instance editor

      break;
    case 'FEATURE_TYPE_ALPHANUMERIC_NON_LOCALIZED':
    case 'FEATURE_TYPE_ALPHANUMERIC':
      value = component.stringValue;
      delete component.stringValue; //cleanup instance editor
      break;
    case 'FEATURE_TYPE_ALPHANUMERIC_SELECTION':
    case 'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION': {
      const optionModels = component.optionModels;
      let selection = optionModels.map((optionModel) => ({
        selectionValue: optionModel.addedOption
          ? optionModel.label
          : optionModel.value,
        addToValueList: false,
        addedSelectionOption: optionModel.addedOption,
        label: optionModel.label,
        selected: optionModel.selected
      }));
      value = {};

      value.selected = selection
        .filter((option) => option.selected)
        // eslint-disable-next-line no-unused-vars
        .map(({ selected, ...optionProps }) => optionProps);

      selection = selection.map(
        // eslint-disable-next-line no-unused-vars
        ({ selected, ...optionProps }) => optionProps
      );

      value.customOptions = selection.filter(
        (option) => option.addedSelectionOption
      );

      const standardOptions = selection.filter(
        (option) => !option.addedSelectionOption
      );
      if (featureValueEditor.classificationIdentifier) {
        value.standardOptions = standardOptions;
      } else {
        setCustomFieldCommonSelectionOptions(
          featureValueEditor.featureIdentifier,
          standardOptions
        );
      }

      delete component.optionModels; //cleanup instance editor
      break;
    }
  }

  return value;
}

export { readFeatureValueForInstance, readFeatureValue };
