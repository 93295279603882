import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  LinearProgress,
  FormControl,
  Dialog,
  DialogTitle
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

import { makeStyles } from '@material-ui/core/styles';
import { getDateTimeString, getDateTimeValueObject } from './DateTime';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex'
  },
  zonedDateTimeEditor: {
    width: '21.5rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  zonedDateTimeEditorInTable: {
    width: '19rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  }
}));

function getTimeZoneString(zonedDateTime) {
  let timeZoneString = '';
  if (zonedDateTime && zonedDateTime.timeZone) {
    timeZoneString = zonedDateTime.timeZone;
  }
  return timeZoneString;
}

export function getZonedDateTimePayloadData(
  dateTimeValue,
  timeZoneValueString
) {
  return {
    dateValue: {
      year: dateTimeValue.year,
      month: dateTimeValue.month,
      day: dateTimeValue.day
    },
    timeValue: { hour: dateTimeValue.hour, minute: dateTimeValue.minute },
    timeZone: timeZoneValueString
  };
}

export const ZonedDateTime = ({
  featureInfo,
  zonedDateTime,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();
  const classes = useStyles();

  const [timeZoneInput, setTimeZoneInput] = useState('');
  const [timeZoneOpen, setTimeZoneOpen] = useState(false);
  //const [timeZonePopupRequest, setTimeZonePopupRequest] = useState(false);

  const title = isInTableContext ? null : featureInfo.title;
  const mandatory = featureInfo.mandatory;
  const hasDefaultData = featureInfo.hasDefaultData;
  const timeZoneList = featureInfo.featureComponent?.timeZoneList || [];

  const dateTimeValueString = getDateTimeString(zonedDateTime);
  const timeZoneValueString = getTimeZoneString(zonedDateTime);
  const [dateTimeBlurState, setDateTimeBlurState] = useState(
    dateTimeValueString
  );

  const isMandatoryError = mandatory && dateTimeValueString == '';
  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && !hasDefaultData) {
    helper = t('common.errorMandatory');
  }

  function saveDateTimeValue(event) {
    const newDateTimeString = event.target.value;

    if (dateTimeBlurState == newDateTimeString || loading) return;
    setDateTimeBlurState(newDateTimeString);

    let newDateTimeValue = null;
    let newZonedTimeValue = null;
    if (newDateTimeString) {
      newDateTimeValue = getDateTimeValueObject(newDateTimeString);
      newZonedTimeValue = {
        ...newDateTimeValue,
        timeZone: timeZoneValueString
      };
    } else if (mandatory && !hasDefaultData) {
      return;
    } else {
      newZonedTimeValue = { timeZone: timeZoneValueString };
    }
    setValueState(newZonedTimeValue);

    if (timeZoneValueString) {
      saveChangedValue(
        newDateTimeValue,
        getZonedDateTimePayloadData(newDateTimeValue, timeZoneValueString)
      );
    } else {
      setTimeZoneOpen(true);
    }
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  function saveTimeZone(_, selectedTimeZone) {
    let newTimeZoneString = null;
    if (selectedTimeZone) {
      newTimeZoneString = selectedTimeZone.id;
    }

    let newZonedTimeValue = null;
    let newDateTimeValue = null;

    if (!newTimeZoneString) {
      return;
    }

    if (dateTimeValueString) {
      newDateTimeValue = getDateTimeValueObject(dateTimeValueString);
      newZonedTimeValue = {
        ...newDateTimeValue,
        timeZone: newTimeZoneString
      };

      saveChangedValue(
        newDateTimeValue,
        getZonedDateTimePayloadData(newDateTimeValue, newTimeZoneString)
      );
    } else {
      newZonedTimeValue = {
        timeZone: newTimeZoneString
      };
    }

    setValueState(newZonedTimeValue);

    // setTimeZoneOpen(false);
  }

  function timeZoneInputChange(_, newInputValue) {
    setTimeZoneInput(newInputValue);
  }

  function timeZoneClose() {
    setTimeZoneOpen(false);
  }

  return (
    <FormControl>
      <div className={classes.wrap}>
        <TextField
          label={title}
          InputLabelProps={{
            shrink: false,
            disableAnimation: true,
            className: classesTitle.title
          }}
          className={
            isInTableContext
              ? classes.zonedDateTimeEditorInTable
              : classes.zonedDateTimeEditor
          }
          variant={isInTableContext ? 'standard' : 'outlined'}
          value={dateTimeValueString}
          type="datetime-local"
          error={Boolean(error) || (isMandatoryError && !hasDefaultData)}
          FormHelperTextProps={{ component: 'div' }}
          helperText={helper}
          onMouseEnter={showError}
          onChange={saveDateTimeValue}
        />
        <span>{timeZoneValueString}</span>
        <Dialog
          onClose={timeZoneClose}
          aria-labelledby="simple-dialog-title"
          open={timeZoneOpen && Boolean(dateTimeValueString)}
        >
          <DialogTitle id="simple-dialog-title">UTC</DialogTitle>
          <Autocomplete
            options={timeZoneList}
            getOptionLabel={(option) => option.label}
            size="small"
            renderInput={(params) => (
              <TextField {...params} autoFocus variant="outlined" />
            )}
            value={timeZoneValueString}
            onChange={saveTimeZone}
            inputValue={timeZoneInput}
            onInputChange={timeZoneInputChange}
            //open={timeZonePopupRequest}
            onBlur={timeZoneClose}
            getOptionSelected={(option, value) => option.id === value}
          />
        </Dialog>
      </div>
    </FormControl>
  );
};
