import React from 'react';
import {
  makeStyles,
  Typography
} from '@material-ui/core';

const useImageStyles = makeStyles(() => ({
  imageContent: {
    maxHeight: '1.8rem',
  }
}));

const TextWithImages = ({ text }) => {
  const imageClasses = useImageStyles();
  const imgTagRegex = /<img[^>]*>/g;

  const parts = [];
  let lastIndex = 0;

  text.replace(imgTagRegex, (match, offset) => {
    if (lastIndex < offset) {
      parts.push(text.slice(lastIndex, offset));
    }
    parts.push(match);
    lastIndex = offset + match.length;
    return match;
  });

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  const elements = parts.map((part, index) => {
    if (part.startsWith('<img')) {
      const src = part.match(/src="([^"]*)"/);
      if (src) {
        return <img key={index} src={src[1]} alt="" className={imageClasses.imageContent} />;
      }
    } else {
      return <span key={index}>{part}</span>;
    }
    return null;
  });

  return [elements];
};

export default function ContentLangText({ product, contentText, classes }) {
  if (!contentText) return null;

  return (
    <>
      {!contentText.missingContentLang ? (
        // <span
        //   className={
        //     product?.description1 || product?.description2
        //       ? 'overviewItemTitle productOverviewTitle'
        //       : 'overviewItemTitle productOverviewTitle bigTitle'
        //   }
        // >
      
        // </span>

        <Typography gutterBottom className={classes.title} noWrap={true} component="div">
          <TextWithImages text={contentText.text} />         
        </Typography>
        // <Typography gutterBottom className={classes.title} noWrap={true} component="div"
        //   innerHTML={contentText.text} />
      ) : (

        <Typography gutterBottom className={classes.title} component="div">
                 &lt;+{contentText.typeName}:{contentText.text}
                &gt;
        </Typography>

        //TODO Tooltip title={missingText} ContantLangText.jsp
        // <span
        //   className={
        //     'missingContentText ' +
        //     (product?.description1 || product?.description2
        //       ? 'overviewItemTitle productOverviewTitle'
        //       : 'overviewItemTitle productOverviewTitle bigTitle')
        //   }

        // >
        //   &lt;+{contentText.typeName}:{contentText.text}
        //   &gt;
        // </span>
      )}

      {/* <spring:message code="contentLang.missingText" arguments="${contentText.missingContentLang}" var="missingText"/>
	<span class="${contentLangTextClass} missingContentText"
		  data-toggle="tooltip" data-placement="auto bottom" title="${missingText}">
		&lt;${contentText.typeName}: 
		<c:out value="${contentText.text}" escapeXml="${escaping}"/>
		&gt;
	</span> */}

      {contentText.textAddon && (
        <span className="contentLangTextAddon">{contentText.textAddon}</span>
      )}
      {/* <c:set var="escaping" value="true" />
      <c:if test="${contentText.editorTextContent}">
        <c:set var="escaping" value="false" />
      </c:if>
      <c:choose>
        <c:when test="${empty contentText.missingContentLang}">
          <span class="${contentLangTextClass}">
            <c:out value="${contentText.text}" escapeXml="${escaping}" />
          </span>
        </c:when>
        <c:otherwise></c:otherwise>
      </c:choose>
      <c:if test="${not empty contentText.textAddon}">
        <span class="contentLangTextAddon">
          <c:out value="${contentText.textAddon}" escapeXml="${escaping}" />
        </span>
      </c:if>
      <c:remove var="escaping" /> */}
    </>
  );
}
