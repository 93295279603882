import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import { usePimStyles } from './ProductStyle';
import PaginationProduct from './PaginationProduct';
import ClassificationSplitButton from './ClassificationSplitButton';
import FavoriteButton from './FavoriteButton';

import ProductContent from './ProductContent';
import Grid from '@material-ui/core/Grid';

import ajaxGet from '../../services/ajaxGet.js';
import ajaxPost from '../../services/ajaxPost.js';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import ButtonGroup from '@mui/material/ButtonGroup';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import FilterProduct from './FilterProduct';

import {MdDelete,MdStarOutline,MdOutlineStar} from "react-icons/md";
import { darkPimColor, mainPimColor } from '../PimTogoTheme';

export default function ProductItem() {
  const theme = useTheme();

  const { t } = useTranslation();
  const [searchExpression, setSearchExpression] = useState('*');
  const [paginationstate, setPaginationstate] = useState({});

  const [productsViewList, setProductsViewList] = useState([]);

  const [contentLanguage, setContentLanguage] = useState($('#contentLangSelectElement').val() || null);

  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(10);
  const [countFoundTotal, setCountFoundTotal] = useState(5);
  const [searched, setSearched] = useState(false);

  const [customInstanceType, setCustomInstanceType] = useState('');

  const [productStructureNodePath, setProductStructureNodePath] = useState('');

  const [productStructureNode, setProductStructureNode] = useState('');

  const [articleDataEdit, setArticleDataEdit] = useState(false);

  const [lastPage, setLastPage] = useState('');

  const [searchWithFacets, setSearchWithFacets] = useState(true);

  const [isNewFilter, setIsNewFilter] = useState(false);

  const [facets, setFacets] = React.useState({});
  const [isFavoriteInstance, setIsFavoriteInstance] = useState(false);
  const [firstRender, setFirstRender] = useState(false);

  const [
    multiClassificationSubscribed,
    setMultiClassificationSubscribed
  ] = useState(false);

  const [ hasContentAutomation, setHasContentAutomation ] = useState(false);
  

  const [classificationGroups, setClassificationGroups] = useState([]);
  const divRefs = useRef({});
  const ref = useRef({});

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTableCell-head': {
        backgroundColor: '#a9a9a975',
        fontSize: '1.5rem',
        fontWeight: 'bold'
      },
      '& .MuiTableCell-body': {
        fontSize: '1.5rem'
      },
      width: '100%',
      '& .MuiSvgIcon-root': { fontSize: '3rem !important' }
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750,
      '& .MuiTableCell-root': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)'
      }
    },
    deleteBtn: {
      fontSize: '2.5rem !important'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    buttonGroup_: {
      backgroundColor: mainPimColor + ' !important',
      borderRight: '0.25px solid #bdbdbd !important',
      borderColor: '#938d85 !important',
      borderRadius: '0px !important',
      '&:hover': {
        boxShadow: '0 0 10px #aaa !important',
        backgroundColor: darkPimColor + ' !important'
      }
    },
    buttonGroup__: {
      backgroundColor: '#555555f2  !important',
      borderRight: '0.25px solid #bdbdbd !important',
      borderColor: '#938d85 !important',
      borderRadius: '0px !important',
      '&:hover': {
        boxShadow: '0 0 10px #aaa !important',
        backgroundColor: '#555 !important'
      }
    },
    dropDownIcon_: {
      fontSize: '2.5rem !important',
      "& .MuiSvgIcon-root": {
        fontSize: "1.8rem !important",
      },
      backgroundColor: mainPimColor + " !important",
      marginTop: "0px !important",
      "&:hover": {
        boxShadow: "0 0 10px #aaa !important",
        backgroundColor: darkPimColor + " !important",
      },
    },
    dropDownIcon__: {
      fontSize: "2.5rem !important",
      "& .MuiSvgIcon-root": { fontSize: "1.8rem !important" },
      backgroundColor: "#555555f2 !important",
      marginTop: "0px !important",
      "&:hover": {
        boxShadow: "0 0 10px #aaa !important",
        backgroundColor: "#555 !important",
      },
    },
    popperStyle_: {
      marginTop: 0
    },
    popperStyle__: {
      zIndex: 1000,
      fontFamily: '"Roboto", sans-serif !important',
      fontSize: '14px !important',
      color: '#333 !important'
    },
    title: {
      fontSize: '1.5rem !important',
      fontWeight: '800 !important'
    },
    subTitle: {
      fontSize: '1.3rem !important',
      fontWeight: '500 !important'
    },
    cardContentStyle: {
      padding: '0px !important',
      width: '100%!important'
    },
    gridStyle: {
      padding: '5px !important'
    }
  }));

  const classes_ = useStyles();

  const handelEventSearchProducts = () => {
    setCurrentPage(1);
    setElementsPerPage(5);
  };

  const searchProducts = (filterByProductStructure) => {

    var curSearchInput = $('.navTextSearchInput').val();
    let sendInfo = {
      // isNewFilter: isNewFilter,
      currentPage: currentPage,
      searchWithFacets: searchWithFacets,
      // filters: filters,
      contentLanguage: contentLanguage,
      // lastPage: lastPage,
      // customInstance: customInstance,
      searchTerm: curSearchInput,
      elementsPerPage: elementsPerPage
    };

    if (filterByProductStructure) {
      sendInfo.productStructureNode =
        filterByProductStructure.productStructureNode;
      sendInfo.productStructureNodePath =
        filterByProductStructure.productStructureNodePath;
    }

    ajaxGet('dataManagement_', sendInfo).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        let productList = response.overviewModel.items;
        setProductsViewList([]);
        setCountFoundTotal(response.countFoundTotal);
        setArticleDataEdit(response.articleDataEdit);
        setPaginationstate(response.pagination);
        setClassificationGroups(response.groupModels);
        setMultiClassificationSubscribed(
          response.multiClassificationSubscribed
        );
        setHasContentAutomation(response.hasContentAutomation)
        setSearchExpression(curSearchInput);
        setProductsViewList(productList);
        setFacets(response.facets);
      }
      setSearched(true);
    });
  };

  // $(".productStructureOverview .showAllProducts")
  // .on("click", function (event) {
  //   renderReactHere () 
  // },{once: true});

  // const renderReactHere = () => {
  //   var curSearchInput= "*";
  //   console.log('togo.productSearch');
  //   let sendInfo = {
  //     currentPage: currentPage,
  //     searchWithFacets: searchWithFacets,
  //     contentLanguage: contentLanguage,
  //     searchTerm: curSearchInput,
  //     elementsPerPage: elementsPerPage
  //   };
  //   ajaxGet('dataManagement_', sendInfo).then((ajaxData) => {
  //     const response = ajaxData.response;
  //     if (!ajaxData.error && response) {
  //       let productList = response.overviewModel.items;
  //       setProductsViewList([]);
  //       setCountFoundTotal(response.countFoundTotal);
  //       setArticleDataEdit(response.articleDataEdit);
  //       setPaginationstate(response.pagination);
  //       setClassificationGroups(response.groupModels);
  //       setMultiClassificationSubscribed(
  //         response.multiClassificationSubscribed
  //       );
  //       setHasContentAutomation(response.hasContentAutomation)
  //       setSearchExpression(curSearchInput);
  //       setProductsViewList(productList);
  //       setFacets(response.facets);
  //     }
  //     setSearched(true);
  //   });
  //  };

  useEffect(() => {
    if (!TOGO.Components.searchListening) {
      TOGO.Components.searchListening = true;
    }
    searchProducts();
    if (!firstRender) {
     //   TOGO.viewStack.getContent().on(loadEvent, renderReactHere);  
    //  TOGO.viewStack.getContent().one('togo.productSearch',function(){
    //   renderReactHere();
    //   },{once: true});
      setFirstRender(true);
    }

    $('aside.navigation-main-sidebar').on(
      'togo.productStructure.filterProductList',
      '.productStructureOverview',
      function (_, filterByProductStructure) {
        searchProducts(filterByProductStructure);
      }
    );
  }, [currentPage, elementsPerPage, searchExpression, firstRender]);

  function handleChangeCurrentPage(newValue) {
    setCurrentPage(newValue);
  }

  function handleChangeElementsPerPage(newValue) {
    setCurrentPage(1);
    setElementsPerPage(newValue);
  }

  const gotoPage = (val) => {
    setCurrentPage(val);
  };

  const openLink = (product) => {
    if (product.url != null && product.url != undefined) {
      window.open(product?.url, '_self');
    }
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const deleteProduct = (id) => {
    var pojo = new Object();
    pojo.productId = id;
    ajaxPost('dataManagement/deleteProduct', pojo).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setProductsViewList(productsViewList.filter((prd) => prd.id !== id));
        TOGO.Util.notifyResponse(ajaxData.response.message, false);
        searchProducts();
      }
    });
  };
  const confirmDeleteOutputChannel = (id) => {
    TOGO.popupManager.confirmZebraDialog(
      t('products.deleteButtonConfirm'),
      deleteProduct,
      id
    );
  };

  return (
  <div className="container-fluid">
    {countFoundTotal > 0 && (
      <Grid className={classes_.gridStyle}>
        <FilterProduct
          setProductsViewList={setProductsViewList}
          setCountFoundTotal={setCountFoundTotal}
          setArticleDataEdit={setArticleDataEdit}
          setClassificationGroups={setClassificationGroups}
          setPaginationstate={setPaginationstate}
          setMultiClassificationSubscribed={setMultiClassificationSubscribed}
          setFacets={setFacets}
          facets={facets}
          contentLanguage={contentLanguage}
        />
      </Grid>
    )}

    {countFoundTotal > 0 && (
      <>
        <Grid className={classes_.gridStyle}>
          {true && (
            <PaginationProduct
              paginationstate={paginationstate}
              searchExpression={searchExpression}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              handleChangeCurrentPage={handleChangeCurrentPage}
              handleChangeElementsPerPage={handleChangeElementsPerPage}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
            />
          )}
        </Grid>
        <div className="overviewListing_ paginationContent updateURL">
          <div className="paginationPageTurner">
            {productsViewList.length > 0 &&
              productsViewList.map((product, idx) => (
                <div
                  className={
                    'row' +
                    (articleDataEdit ? '' : ' noArticleDataEditRoleError')
                  }
                  data-id={product.id}
                  key={product?.id}

                  onClick={() => {
                    openLink(product);
                  }}
                >
                  {product.error ? (
                    <>
                      <div className="col errorItemProduct">
                        <span className="overviewItemTitle productOverviewTitle">
                          {product.title}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {articleDataEdit ? (
                        <div
                          className="contentlangRelevantItem"
                          onClick={() => {
                            openLink(product);
                          }}
                        >
                          <ProductContent
                            product={product}
                            classes={classes_}
                          ></ProductContent>
                        </div>
                      ) : (
                        <ProductContent
                          product={product}
                          classes={classes_}
                        ></ProductContent>
                      )}
                      <div className="overviewActionsWrapper">
                        <div className="overviewActions">

                          <ButtonGroup orientation="vertical">
                            <ClassificationSplitButton
                              pdf__URL={product.actions[0]?.url}
                              anchorRef={(divRefs.current[idx] = ref)}
                              productID={product.id + '___' + idx}
                              buttonGroupStyle={classes_.buttonGroup_}
                              dropDownIconStyle={classes_.dropDownIcon_}
                              classificationGroups={classificationGroups}
                              contentLanguage={contentLanguage}
                              buttonToolTipTitle={t('productType.datasheet')}
                            />
                            {multiClassificationSubscribed && hasContentAutomation  ?  (
                              <ClassificationSplitButton
                                pdf__URL={product.actions[1]?.url}
                                anchorRef={(divRefs.current[idx] = ref)}
                                productID={product.id + '_' + idx}
                                buttonGroupStyle={classes_.buttonGroup__}
                                dropDownIconStyle={classes_.dropDownIcon__}
                                classificationGroups={classificationGroups}
                                contentLanguage={contentLanguage}
                                buttonToolTipTitle={t(
                                  'productType.datasheetWithoutDefaultData'
                                )}
                              />
                            ) : (
                          articleDataEdit &&
                              <Tooltip
                                title={
                                  <Typography variant="h6">Delete</Typography>
                                }
                              >
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    confirmDeleteOutputChannel(product.id);
                                  }}
                                  variant="contained"
                                  color="error"
                                  className={
                                    'editAction iconButton  overviewItemDeleteButton iconButton_' 
                                  }
                                >
                                  <MdDelete
                                    size={30}
                                  ></MdDelete>
                                </Button>
                              </Tooltip>
                            )}
                          </ButtonGroup>
                          {articleDataEdit && multiClassificationSubscribed && hasContentAutomation && (
                            <Tooltip
                              title={
                                <Typography variant="h6">Delete</Typography>
                              }
                            >
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  confirmDeleteOutputChannel(product.id);
                                }}
                                variant="contained"
                                color="error"
                                className={
                                  'editAction iconButton  overviewItemDeleteButton'
                                }
                              >
                                <MdDelete
                                  size={30}
                                ></MdDelete>
                              </Button>
                            </Tooltip>
                          )}
                          <FavoriteButton
                          objectClassName={product?.className}
                          instanceId={product.id}
                          >

                          </FavoriteButton>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
        <Grid className={classes_.gridStyle}>
          {true && (
            <PaginationProduct
              paginationstate={paginationstate}
              searchExpression={searchExpression}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              handleChangeCurrentPage={handleChangeCurrentPage}
              handleChangeElementsPerPage={handleChangeElementsPerPage}
            />
          )}
        </Grid>
      </>
    )}
    {countFoundTotal == 0 && (
      <Grid>
        <Container maxWidth="sm">
          <Typography variant="h5">Keine Suchergebnisse gefunden</Typography>
        </Container>
      </Grid>
    )}
  </div>
  );
}
